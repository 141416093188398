<template>
  <div class="animated fadeIn">
    <b-card>
      <!-- Customer Information -->
      <b-card-header>
        Customer Information
        <div class="card-header-actions">
          <a class="card-header-action" href="gold-sell-partner" rel="noreferrer noopener">
            <small class="text-muted">Back</small>
          </a>
        </div>
      </b-card-header>
      <b-card-body>
        <div class="loading" v-if="isLoading == true">
          <div class="sk-three-bounce">
            <div class="sk-child sk-bounce1"></div>
            <div class="sk-child sk-bounce2"></div>
            <div class="sk-child sk-bounce3"></div>
          </div>
        </div>
        <b-row class="mb-4">
          <b-col sm="4">
            <h6>User Name</h6>
            <div class="mb-3"><strong>{{ this.dataCustomer.customer_information.name || '-' }}</strong></div>
            <h6>Partner</h6>
            <div class="mb-3"><strong>{{ this.dataCustomer.customer_information.partner || '-' }}</strong></div>
          </b-col>
          <b-col sm="4">
            <h6>Email</h6>
            <div class="mb-3"><strong>{{ this.dataCustomer.customer_information.email || '-' }}</strong></div>
            <h6>Gold Balance</h6>
            <div class="mb-3"><strong>{{ this.dataCustomer.customer_information.gold_balance || '-' }}</strong></div>
          </b-col>
          <b-col sm="4">
            <h6>Phone</h6>
            <div class="mb-3"><strong>{{ this.dataCustomer.customer_information.phone || '-' }}</strong></div>
          </b-col>
        </b-row>
      </b-card-body>
      <!-- Transaction Information -->
      <b-card-header>
        Transaction Information
      </b-card-header>
      <b-card-body>
        <div class="loading" v-if="isLoading == true">
          <div class="sk-three-bounce">
            <div class="sk-child sk-bounce1"></div>
            <div class="sk-child sk-bounce2"></div>
            <div class="sk-child sk-bounce3"></div>
          </div>
        </div>
        <b-row class="mb-4">
          <b-col sm="4">
            <h6>Nomor Invoice</h6>
            <div class="mb-3"><strong>{{ this.dataCustomer.transaction_information.invoice_number || '-' }}</strong></div>
            <h6>Description</h6>
            <div class="mb-3"><strong>{{ this.dataCustomer.transaction_information.description || '-' }}</strong></div>
            <h6>Request Date</h6>
            <div class="mb-3"><strong>{{ this.dataCustomer.transaction_information.request_date || '-' }}</strong></div>
          </b-col>
          <b-col sm="4">
            <h6>IDR Amount</h6>
            <div class="mb-3"><strong>{{ this.dataCustomer.transaction_information.amount || '-' }}</strong></div>
            <h6>Gold Amount</h6>
            <div class="mb-3"><strong>{{ this.dataCustomer.transaction_information.gold_amount || '-' }}</strong></div>
            <h6>Release Date</h6>
            <div class="mb-3"><strong>{{ this.dataCustomer.transaction_information.release_date || '-' }}</strong></div>
          </b-col>
          <b-col sm="4">
            <h6>Transaction Status</h6>
            <div class="mb-3"><strong>{{ this.dataCustomer.transaction_information.status || '-' }}</strong></div>
            <h6>Reason</h6>
            <div class="mb-3"><strong>{{ this.dataCustomer.transaction_information.reason || '-'  }}</strong></div>
          </b-col>
        </b-row>
      </b-card-body>
      <!-- Bank Information -->
      <b-card-header>
        Bank Information
      </b-card-header>
      <b-card-body>
        <div class="loading" v-if="isLoading == true">
          <div class="sk-three-bounce">
            <div class="sk-child sk-bounce1"></div>
            <div class="sk-child sk-bounce2"></div>
            <div class="sk-child sk-bounce3"></div>
          </div>
        </div>
        <b-row class="mb-4">
          <b-col sm="4">
            <h6>Tujuan Pencairan Dana</h6>
            <div class="mb-3"><strong>{{ this.dataCustomer.bank_account_information.bank_name || '-' }}</strong></div>
          </b-col>
          <b-col sm="4">
            <h6>Account Receiver Number</h6>
            <div class="mb-3"><strong>{{ this.dataCustomer.bank_account_information.account_number || '-' }}</strong></div>
          </b-col>
          <b-col sm="4">
            <h6>Account Receiver Name</h6>
            <div class="mb-3"><strong>{{ this.dataCustomer.bank_account_information.reciever_name || '-' }}</strong></div>
          </b-col>
        </b-row>
      </b-card-body>
      <!-- Table History -->
      <b-card-header>
        History
      </b-card-header>
      <b-card-body>
        <div>
          <vuetable ref="vuetable"
            :api-mode="false"
            :fields="fields"
            :per-page="limit"
            :data-manager="dataManager"
            pagination-path="pagination"
            @vuetable:pagination-data="onPaginationData">
              <template slot="name-slot" slot-scope="prop">
                <span>{{ prop.rowData.users.name }}</span>
              </template>
              <template slot="no_invoice-slot" slot-scope="prop">
                <span>{{ prop.rowData.trans_id }}</span>
              </template>
              <template slot="bank_name-slot" slot-scope="prop">
                <span>{{ prop.rowData.recipient }}</span>
              </template>
              <template slot="amount-slot" slot-scope="prop">
                <span>{{ prop.rowData.amount }}</span>
              </template>
              <template slot="gold-amount" slot-scope="prop">
                <span>{{ prop.rowData.gold_amount }}</span>
              </template>
            </vuetable>
          <div class="vuetable-pagination ui basic segment grid">
            <div class="wrapper-pagination-custom">
              <div class="pagination-custom-info">
                <p>{{ this.paginationInfo }}</p>
              </div>
              <div class="pagination-custom-button">
                <button :disabled="currentPage === 1" @click="doMovePage('first')">
                  <i v-if="currentPage === 1" class="fa fa-angle-double-left disabled"></i>
                  <i v-else class="fa fa-angle-double-left"></i>
                </button>
                <button :disabled="currentPage === 1" @click="doMovePage('prev')">
                  <i v-if="currentPage === 1" class="fa fa-angle-left disabled"></i>
                  <i v-else class="fa fa-angle-left"></i>
                </button>
                <div>{{ this.currentPage }}</div>
                <button :disabled="data.length < 10" @click="doMovePage('next')">
                  <i v-if="data.length < 10" class="fa fa-angle-right disabled"></i>
                  <i v-else class="fa fa-angle-right"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate"
import { required } from "vuelidate/lib/validators"
import accounting from 'accounting'
import Vuetable from 'vuetable-2/src/components/Vuetable'
import _ from "lodash"

export default {
  name: 'detail-gold-sell-partner',
  components: {
    Vuetable
  },
  data() {
    return {
      page: 1,
      currentPage: 1,
      paginationInfo: '',
      limit: 10,
      form: {
        reason: "",
      },
      email: "",
      phone: "",
      hashed: '',
      address: "",
      status: "",
      can_retry: false,
      description: "",
      amount: "",
      gold_amount: "",
      status_text: null,
      gold_balance: "",
      created_at: "",
      updated_at: "",
      bank: "",
      bank_code: "",
      listing: false,
      reason: "",
      admin_fee: "",
      invoice_number: null,
      partner: '',
      bucket_balance: null,
      bucket_balance_gram: '',
      name: "",
      user: '',
      data: [],
      dataCustomer: {},
      apiUrl: process.env.VUE_APP_SECRET + process.env.VUE_APP_CONFIG + `history-withdraw-sell/` + this.$route.params.id,
      HttpOptions: {
        headers: {
          'Accept' : 'application/json',
          'Authorization' : 'Bearer ' + localStorage.getItem('access_token')
        }
      },
      sort: 'withdraw_sell.created_at|desc',
      sortOrder: [
        {
          field: 'created_at',
          direction: 'desc'
        }
      ],
      moreParams: {},
      fields: [
        {
          name: 'created_at',
          sortField: 'withdraw_sell.created_at',
          title: 'Date'
        },
        {
          name: 'name-slot',
          sortField: 'users.name',
          title: 'Name'
        },
        {
          name: 'no_invoice-slot',
          sortField: 'invoice.trans_id',
          title: 'Nomor Invoice'
        },
        {
          name: 'bank_name-slot',
          sortField: 'bank_list.bank_name',
          title: 'Bank Account'
        },
        {
          name: 'amount-slot',
          sortField: 'withdraw_sell.amount',
          title: 'Nilai Rupiah'
        },
        {
          name: 'gold_amount',
          sortField: 'withdraw_sell.gold_amount',
          title: 'Jumlah Emas'
        },
        {
          name: 'status',
          sortField: 'trans_id.status'
        }
      ],
      errors: {
        message: [],
        status: [],
        code: '',
        headers: [],
        form: {
          reason: [],
        },
      },
      isLoading: false
    }
  },
  computed: {
    formStr() { return JSON.stringify(this.form, null, 4) },
    isValid() { return !this.$v.form.$anyError },
    isDirty() { return this.$v.form.$anyDirty },
    invCheck() { return 'You must accept before submitting' },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      reason: {
        required
      }
    }
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    data(newVal, oldVal) {
      this.$refs.vuetable.refresh();
    }
  },
  created () {
    this.onFetchDetail()
    this.onFetchHistory()
  },
  methods: {
    doMovePage(value) {
      this.$events.$emit('movePage', value)
    },
    onFetchDetail() {
      this.$http.get(`partner-gold-sell/detail?hashed_id=` + this.$route.params.id)
      .then((result) => {
        this.dataCustomer = result.data.data;
      }).catch((error) => {
        if (error.response) {
          this.errors.code = error.response.status;
          this.errors.message = error.response.data.meta.message;
          this.errors.status = error.response.data.meta.code;
        }
      })
    },
    onFetchHistory() {
      this.$http.get(`partner-gold-sell/list?sort=${encodeURIComponent(this.sort)}&page=${this.page}&per_page=${this.limit}&user_id=${atob(this.$route.params.user_id)}`)
        .then((response) => {
          const startIndex = (response.data.current_page - 1) * this.limit;
          const endIndex = startIndex + this.limit;

          this.data = response.data.data;
          this.currentPage = response.data.current_page;
          this.paginationInfo = response.data.data.length > 0 ? `Displaying ${startIndex + 1} to ${endIndex}` : 'No relevant data';

          if (this.loadingTable) this.loadingTable = false;
        })
        .catch((err) => {
          this.handleLoadError(err)
        })
    },
    handleLoadError(error) {
      this.errors.code = error.response.data.meta.code;
      this.errors.message = error.response.data.meta.message;
      this.errors.status = error.response.data.meta.code;
      if (this.errors.code == 401) {
        if (localStorage.getItem('access_token') != null) {
          localStorage.removeItem('access_token');
          this.$swal.fire(
            'Your session expired!',
            'Your session has expired. Please login again to access this page!',
            'error'
          ).then(() =>{
            this.$router.push("/login")
          })
        }
      }else if(this.errors.code == 403) {
        this.$router.push("/403")
      }else if(this.errors.code == 500) {
        this.$router.push("/500")
      }
    },
    formatNumber (value) {
      return 'Rp.'+accounting.formatNumber(value, 2)
    },
    number(value){
      return value
    },
    onChangePage (page) {
      this.$refs.vuetable.changePage(page)
    },
    onPaginationData (paginationData) {
      console.log('paginationData', paginationData)
      // this.$refs.pagination.setPaginationData(paginationData)
      // this.$refs.paginationInfo.setPaginationData(paginationData)
    },
    reject() {
      this.validate()
      this.isLoading = true
      this.$http.post(`withdraw-set-failed`, {
        user: this.user,
        reason: this.form.reason
      }).then(() => {
        this.isLoading = false
        this.$swal.fire(
          'Success!',
          'Jual emas berhasil ditolak!',
          'success'
        ).then(() => {
          location.reload()
        })
      }).catch((error) => {
        if (error.response) {
          this.isLoading = false
          this.errors.code = error.response.status;
          this.errors.status = error.response.data.meta.code;
          this.errors.headers = error.response.headers;
          if(this.errors.status == 422) {
              this.errors.message = error.response.data.meta.message;
              this.errors.form.reason = this.errors.message.reason;
          }else if (this.errors.status  == 400) {
            this.errors.message = error.response.data.meta.message;
            this.$swal.fire(
              'Failed',
              this.errors.message,
              'error'
            ).then(() => {
              location.reload()
            })
          }else if (this.errors.status  == 404) {
            this.errors.message = error.response.data.meta.message;
            this.$swal.fire(
              'Failed',
              this.errors.message,
              'error'
            ).then(() => {
              location.reload()
            })
          }
        }
      })
    },
    chkState(val) {
      const field = this.$v.form[val]
      return !field.$dirty || !field.$invalid
    },
    findFirstError(component = this) {
      if (component.state === false) {
        if (component.$refs.input) {
          component.$refs.input.focus()
          return true
        }
        if (component.$refs.check) {
          component.$refs.check.focus()
          return true
        }
      }
      let focused = false
      component.$children.some((child) => {
        focused = this.findFirstError(child)
        return focused
      })

      return focused
    },
    validate() {
      this.$v.$touch()
      this.$nextTick(() => this.findFirstError())
      return this.isValid
    },
    dataManager(sortOrder, pagination) {
      let local = this.data;

      if (sortOrder.length > 0) {
        const newSort = `${sortOrder[0].sortField}|${sortOrder[0].direction}`;

        if (this.sort !== newSort) {
          this.sort = newSort;
          this.onFetchHistory();
        }
      }

      pagination = this.$refs.vuetable.makePagination(
        local.length,
        this.limit
      );

      let from = pagination.from - 1;
      let to = from + this.limit;

      return {
        pagination: pagination,
        data: local.length > 0 ? _.slice(local, from, to) : []
      };
    },
    onMovePage(eventData) {
      if (eventData === 'first') {
        this.page = 1;
        this.onFetchHistory();
      } else if (eventData === 'prev') {
        this.page--;
        this.onFetchHistory();
      } else {
        this.page++;
        this.onFetchHistory();
      }
    }
  },
  mounted() {
    this.$events.$on('movePage', eventData => this.onMovePage(eventData))
  }
}
</script>

<style>
    .submt {
        width: 100%;
        height: auto;
        display: block;
    }
</style>
<style>
/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
}

/* Pagination Custom */
.wrapper-pagination-custom {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f9fafb;
}

.pagination-custom-button {
  display: flex;
}

.pagination-custom-button button {
  width: 28px;
  height: 47px;
  padding: 13px 16px;
  font-size: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FFFFFF;
  border: solid rgba(0,0,0,0.3);
}

.pagination-custom-button button:first-child {
  padding: 13px 16px 13px 30px;
  border-radius: 4px 0 0 4px;
  border-width: 1px 0 1px 1px
}

.pagination-custom-button button:nth-child(2),
.pagination-custom-button button:last-child {
  font-size: 1.3em;
  border-width: 1px;
}

.pagination-custom-button button:last-child {
  border-radius: 0 4px 4px 0;
}

.pagination-custom-button button i.disabled {
  opacity: 0.6;
}

.pagination-custom-button div {
  width: 28px;
  height: 47px;
  font-size: 1em;
  font-weight: 500;
  background: #FFFFFF;
  border: solid rgba(0,0,0,0.3);
  border-width: 1px 0 1px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

</style>
<style src="spinkit/scss/spinkit.scss" lang="scss" />
